import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import SubText from "../../../components/subtext"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"

import { useTheme } from "../../../services/theme"


const HubspotDisconnectFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I disconnect a Hubspot account?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    title="How do I disconnect a Hubspot account?"
                    maxWidth="960px"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="768px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Choose connection </SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click your profile icon (top right) and click 'Integrations'.<br/><br/>
                        &#8594;Choose the Hubspot account you want to disconnect.<br/><br/>
                    </SegmentText>
                    <SegmentSubTitle>Step 2: Disconnect</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Disconnect'. 
                        <SubText align="left">Disconnecting a Hubspot account does not remove any forms you've added from that account.
                         They will continue to work until you remove the website or form from Vimkit.</SubText>
                    </SegmentText>
                    <Screenshot fluid={data.disconnectHubspotScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="768px" marginBottom="40px"/>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Once a Hubspot account is disconnect, Vimkit will no longer list the forms available from that Hubspot account.
                    </SegmentText>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default HubspotDisconnectFaq

export const query = graphql`
    query {
        disconnectHubspotScreenshot: file(relativePath: { eq: "screenshots/disconnecthubspot.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`